<template>
  <div class="main">
    <div class="content">
      <div class="course">
        <div class="tabs">
          <p v-for="item in courseModuleList" :key="item" class="tab" :class="{'active':  item.id === '1'}" @click="tabIndex = item.id">{{ item.name }}</p>
        </div>
        <div class="course-list">
          <ul>
            <li v-for="(item, index) in courseList" :key="index">
              <div class="course-img">
                <img src="@/assets/index/banner.png" alt="">
              </div>
              <div class="course-info">
                <p class="course-time">{{ item.created_at }}</p>
                <p class="course-title">{{ item.name }}</p>
                <p class="course-desc">{{ item.intro }}</p>
                <!-- <p class="course-teacher"> 
                  <img src="@/assets/laoshi.png" alt=""> 
                  <span>主讲老师：</span>
                  <span class="teacher-name">朱先波</span>
                </p> -->
              </div>
            </li>
          </ul>
        </div>
      </div>
      <pagination
        :num="num"
        :limit="limit"
        @getNew="getNew"
        v-if="num"
      ></pagination>
    </div>
  </div>
</template>
<script>
import pagination from "@/components/pc/pagination.vue";
export default {
  name: 'advicePage',
  components: {
    pagination,
  },
  data () {
    return {
      tabIndex: '1',
      num: 10, //数据总个数
      limit: 10, //一页显示几个
      nowPage: 1, //当前页码
      courseList: [],
      courseModuleList: []
    }
  },
  created () {
    this.ID = this.$route.query.ID
    this.getModuleList()
  },
  methods: {
    getModuleList() {
      this.$axios.post(`/v1/qn/courseModuleList`, { area_id: this.ID }).then((res) => {
        if (res.code == 0) {
          this.courseModuleList = res.data.data;
          if (res.data.data.length > 0) {
            this.getList(res.data.data[0].id)
          }
        }
      });
    },
    getList (id) {
      this.tabIndex = id;
      this.courseList = [];
      this.$axios.post(`/v1/qn/courseList`, { area_id: this.ID, module_id: id }).then((res) => {
        if (res.code == 0) {
          this.courseList = res.data.data;
        }
      });
    },
    // 翻页
    getNew(nowPage) {
      this.nowPage = nowPage;
      this.getList();
    },
  }
}
</script>
<style lang="scss" scoped>
.main {
  background-color: #FFFFFF;
  .content {
    width: 1200px;
    margin: 0 auto;
    padding-top: 60px;
    padding-bottom: 120px;
    background-color: #FFFFFF;
    .tabs {
      display: flex;
      justify-content: flex-start;
      .tab {
        height: 50px;
        padding: 0px 23px;
        background: #F0F6FF;
        border-radius: 5px 5px 5px 5px;
        font-weight: 500;
        font-size: 22px;
        color: #7EA0F0;
        line-height: 50px;
        text-align: center;
        margin: 0 25px;
      }
      .active {
        cursor: pointer;
        background: #366CEC;
        color: #FFFFFF;
      }
    }
    .course {
      .course-list {
        margin-top: 10px;
        ul {
          display: flex;
          flex-wrap: wrap;
          li {
            margin: 20px 19px;
            .course-img {
              width: 355px;
              height: 200px;
              border-radius: 10px 10px 0px 0px;
              img {
                width: 100%;
                height: 100%;
                border-radius: 10px 10px 0px 0px;
              }
            }
            .course-info {
              width: 355px;
              // height: 178px;
              padding: 20px;
              box-sizing: border-box;
              background: #FFFFFF;
              box-shadow: 0px 10px 18px 1px rgba(180,180,180,0.16);
              border-radius: 0px 0px 10px 10px;
              .course-time {
                font-weight: 500;
                font-size: 14px;
                color: #666666;
                line-height: 20px;
                text-align: left;
              }
              .course-title {
                font-weight: bold;
                font-size: 18px;
                color: #333333;
                line-height: 25px;
                text-align: left;
                margin-top: 12px;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
                overflow: hidden;
              }
              .course-desc {
                font-weight: 500;
                font-size: 14px;
                color: #666666;
                line-height: 22px;
                text-align: left;
                margin-top: 12px;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
              }
              .course-teacher {
                display: flex;
                align-items: center;
                margin-top: 12px;
                img {
                  width: 16px;
                  height: 16px;
                }
                span {
                  font-weight: bold;
                  font-size: 12px;
                  line-height: 23px;
                  color: #666666;
                  text-align: left;
                }
                .teacher-name {
                  color: #366CEC;
                  padding-left: 10px;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>